$text-color: #00132a;

$menu-background: #00132a;
$menu-item-title: #fff;
$menu-item-subtitle: #bfbecb;
$menu-item-divider: #222623;

$dashboard-background: #f0f2f5;

$primary: #0ab884;
$primary-light: #a5e4d1;
$primary-dark: #00ad71;
