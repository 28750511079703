@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

ul {
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    padding: 0;
  }
}

p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
  color: $text-color;
}

.ribbon {
  background-color: $primary;
  height: 10px;
  border-bottom: 2px solid $primary-dark;
}
