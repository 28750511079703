@import "~bootstrap/scss/bootstrap";

.modal-dialog {
  .modal-footer {
    border: none;
    justify-content: center;
  }
}

.card {
  border: 0;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
}

.bottom-buffer {
  margin-bottom: 30px;
}
